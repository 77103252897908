













import { defineComponent, computed } from '@vue/composition-api';
import sink from '@/sink';
import { ActionCard } from '@conversa/bedazzled/src/cards';
import { HoverLinkCardProps } from './models/hover-link-card-props.interface';

export default defineComponent({
  components: { ActionCard },
  setup() {
    const cardsSrc: HoverLinkCardProps[] = [
      {
        to: 'patients',
        title: 'Patients',
        description: 'Search for Patients in your organization.',
      },
      {
        to: 'chats',
        title: 'Chats',
        description: 'Search for Chats taken by Patients in your organization.',
      },
      {
        to: 'responses',
        title: 'Responses',
        description: 'Search for Responses to specific Chat questions.',
      },
      {
        to: 'patient-engagement',
        title: 'Patient Engagement',
        description:
          'Search for Patients and their most recent Chat engagement state',
      },
      {
        to: 'activity',
        title: 'Activity',
        description: 'Search for Activities related to Patient communications.',
      },
      {
        to: 'reporting',
        title: 'Reporting',
        description: 'View program metrics and analytics.',
      },
      {
        to: 'feedback',
        title: 'Feedback',
        description: 'View Feedback provided by Patients from Chats.',
        enabledSink: 'capabilities.features.feedback',
      },
    ];

    const cards = computed(() => {
      return cardsSrc.filter(card =>
        card.enabledSink ? sink.select(card.enabledSink).value : true,
      );
    });

    return {
      cards,
    };
  },
});
